import React, { useMemo, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { useMediaPredicate } from "react-media-hook";

export const ThemeContext = React.createContext();

const initErrorState = { error: false, message: "" };
let timer;

export const ThemeProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          colors {
            blue
            lightBlue
            grey
            green
            lightGreen
            white
          }
        }
      }
    }
  `);

  const margin = 8;
  const { colors } = data.site.siteMetadata;

  const mobileVal = useMediaPredicate("(max-width: 768px)");
  const [error, setError] = useState(initErrorState);
  const [scrolled, setScrolled] = useState();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobile(mobileVal);
    }
  }, [mobileVal]);
  const setErrorNotification = error => {
    clearTimeout(timer);
    setError(error);
    timer = setTimeout(() => {
      setError(initErrorState);
    }, 3000);
  };

  const context = useMemo(
    () => ({
      colors,
      margin,
      mobile,
      error,
      scrolled,
      setScrolled,
      setErrorNotification,
    }),
    [colors, mobile, error, scrolled]
  );
  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};
